import {toast} from 'react-toastify';
import {TOAST_CONFIG} from "../constant/appConstant";

const ToastHelper = {
    // Success toast
    showSuccess: (message) => {
        toast.success(message);
    },

    // Error toast
    showError: (error) => {
        toast.error(error);
    },

    // warning toast
    showWarning: (message) => {
        toast.warning(message)
    },

    showWarningDisconnect: (message) => {
        toast.warn(message, {
            toastId: TOAST_CONFIG.SOCKET_DISCONNECT_ID,
            autoClose: false,
            closeOnClick: true,
            position: "bottom-left",
        })
    },

    closeToast: (messageId) => toast.dismiss(messageId)
};

export default ToastHelper;
