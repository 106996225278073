import {createSlice} from "@reduxjs/toolkit";
import {REHYDRATE} from "redux-persist"

export const appSlice = createSlice({
    name: "app", initialState: {
        isMobile: undefined
    }, reducers: {
        changeMobile: (state, action) => {
            const {payload} = actions
            state.mobile = payload
        }
    },
    extraReducers: builder => {
        builder.addCase(REHYDRATE, (state, action) => {
        })

    }
})
export const {reducer, actions} = appSlice


export const {changeMobile} = actions
export default reducer