import axios from 'axios';
import ToastHelper from "../general/helper/toastHelper";
import queryString from 'query-string';
import PreferenceKey from "../general/constant/preferenceKey";
import UserHelper from "../general/helper/userHelper";

const sTag = '[AxiosClient]';

const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
        'content-type': 'application/json',
    },
    paramsSerializer: (params) => queryString.stringify(params),
});

axiosClient.interceptors.request.use(async (config) => {
    // console.log(
    //     `${sTag} - ${config.baseURL}${config.url}, ${config.method}, ${
    //         config.method === 'post' ? JSON.stringify(config.data) : JSON.stringify(config.params)
    //     }`
    // );
    // console.log(`${sTag} - headers: ${JSON.stringify(config.headers.common)}`);
    return config;
});

axiosClient.interceptors.response.use(
    (response) => {
        if (response.headers['session-token'])
            axiosClient.defaults.headers.common['session-token'] = response.headers['session-token'];

        if (response && response.data) {
            return response.data;
        }

        return response;
    },
    (error) => {
        console.log(`${sTag} - ${error}`);
        let errorMessage = null;
        const response = error.response;
        if (response && (response.status === 401 || response.status === 403)) {
            UserHelper.signOut();
            window.location.href = '/auth/sign-in';
            return;
        }
        if (response && response.data) {
            const data = response.data;
            const {result, reason, detail} = data;
            if (result === 'failed') {
                if (reason) {
                    errorMessage = reason;
                } else if (detail) {
                    errorMessage = detail;
                }
            }
        }
        if (errorMessage) {
            ToastHelper.showError(errorMessage);
        }
        throw error;
    }
);

// Update base url
const updateAxiosBaseURL = (baseUrl) => {
    axiosClient.defaults.baseURL = baseUrl;
};

// Update api key
const updateAxiosApiKey = (apiKey) => {
    axiosClient.defaults.headers.common['apiSecret'] = apiKey;
};

// Remove api key
const removeAxiosApiKey = () => {
    delete axiosClient.defaults.headers.common['apiSecret'];
};

const updateAccessToken = (accessToken) => {
    axiosClient.defaults.headers.common['Authorization'] = "Bearer " + accessToken;
};

// Remove api key
const removeAccessToken = () => {
    delete axiosClient.defaults.headers.common['Authorization'];
};

(() => {
    const accessToken = localStorage.getItem(PreferenceKey.accessToken)
    if (accessToken) {
        updateAccessToken(accessToken)
    } else {
        UserHelper.signOut()
    }
})();

export {
    updateAxiosApiKey,
    removeAxiosApiKey,
    updateAxiosBaseURL,
    updateAccessToken,
    removeAccessToken
};

export default axiosClient;
