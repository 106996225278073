import AxiosClient from "./axiosClient";

const ConversationSessionApi = {
    overview: (params) => {
        const url = "conversation-session/overview"
        return AxiosClient.get(url, {params})
    },
    category: (params) => {
        const url = "conversation-session/category"
        return AxiosClient.get(url, {params})
    }

}
export default ConversationSessionApi