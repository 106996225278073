import {io} from "socket.io-client"
import PreferenceKey from "../general/constant/preferenceKey";

async function getIPAddress() {
    const response = await fetch("https://api.ipify.org?format=json");
    const data = await response.json();
    return data.ip;
  }
const ipAddress = await getIPAddress()
const SocketIoClient = io(process.env.REACT_APP_URL_SOCKET_IO, {
    transports: ["websocket"],
    path: "/conversation",
    auth: {
        ipAddress: ipAddress,
    },
})

SocketIoClient.on("connect_error", err => {
    console.log((new Date()).toLocaleTimeString(), "[connect_error]-message:", err.message, "code:", err.code, "name:", err.name)
})

export const updateSocketIOToken = (accessToken) => {
    SocketIoClient.auth.authorization = "Bearer " + accessToken
    SocketIoClient.io.opts.auth.authorization = "Bearer " + accessToken
    if (SocketIoClient.connected) {
        SocketIoClient.disconnect().connect()
    } else {
        SocketIoClient.connect()
    }
}

export const updateSocketIOChatbotId = (chatbotId) => {
    SocketIoClient.auth.chatbotId = chatbotId
    SocketIoClient.io.opts.auth.chatbotId = chatbotId
    if (SocketIoClient.connected) {
        SocketIoClient.disconnect().connect()
    } else {
        SocketIoClient.connect()
    }
}

export const removeSocketIOToken = () => {
    SocketIoClient.disconnect().connect()
    delete SocketIoClient.auth.authorization;
}

(() => {
    const accessToken = localStorage.getItem(PreferenceKey.accessToken)
    if (accessToken) {
        updateSocketIOToken(accessToken)
    }
})();

export default SocketIoClient