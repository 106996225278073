import AxiosClient from "./axiosClient";

const AccountApi = {
    getProfile: (params) => {
        const url = "/account/profile"
        return AxiosClient.get(url, {params})
    },
    update: (data) => {
        const url = "/account/update"
        const bodyFormData = new FormData()
        for (const [key, value] of Object.entries(data)) {
            bodyFormData.append(key, value)
        }
        return AxiosClient({
            method: "put",
            url,
            headers: {"Content-Type": "multipart/form-data"},
            data: bodyFormData
        })
    },
    changePwd: (params) => {
        const url = "/account/change-password"
        return AxiosClient.put(url, params)
    },
    getAll: (params) => {
        const url = "/account/all"
        return AxiosClient.get(url, {params})
    },
    addMoney : (params) => {
        const url = "account/add_money"
        return AxiosClient.put(url, params)
    },
}

export default AccountApi