import firebase from 'firebase/compat/app';
import 'firebase/compat/messaging';

const firebaseConfig = {
    apiKey: "AIzaSyA6In_h7K6z2sE6sj-ERGaNCreyUhj5Moo",
    authDomain: "chatbot-mdc.firebaseapp.com",
    projectId: "chatbot-mdc",
    storageBucket: "chatbot-mdc.appspot.com",
    messagingSenderId: "270196650673",
    appId: "1:270196650673:web:3a92fee4cfee667fa6a890",
    measurementId: "G-7XXT64JGGV"
};

// Initialize Firebase only if not already initialized
if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

const messaging = firebase.messaging();

export { messaging };
