import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {REHYDRATE} from "redux-persist"
import {initTime} from "../general/constant/appConstant";
import MessageApi from "../api/messageApi";
import ConversationApi from "../api/conversationApi";

export const thunkStatisticMessage = createAsyncThunk("statistic/message", (params, thunkAPI) => {
    return MessageApi.staticMessage(params)
})

export const thunkStatisticFinance = createAsyncThunk("statistic/finance", (params, thunkAPI) => {
    return MessageApi.staticFinance(params)
})

export const thunkConversationOverview = createAsyncThunk("statistic/conversationOverview", (params, thunkAPI) => {
    return ConversationApi.overview(params)
})

export const thunkChatByTime = createAsyncThunk("statistic/chatByTime", (params, thunkAPI) => {
    return ConversationApi.chatByTime(params)
})

export const statisticSlice = createSlice({
    name: "statistic",
    initialState: {
        finance: {
            startDate: initTime.startDate,
            endDate: initTime.endDate,
            platform: null,
            total: null,
            amountByDays: []
        },
        message: {
            startDate: initTime.startDate,
            endDate: initTime.endDate,
            platform: null,
            messageByDays: []
        },
        overview: {
            sum: 0,
            request: 0,
            support: 0,
            miss: 0
        },
        chatByTime: {
            sumSessions: 0,
            sumCustomers: 0,
            sessions: []
        }
    }, reducers: {}, extraReducers: builder => {
        builder.addCase(REHYDRATE, (state, action) => {
            state.isGettingStatisticMessage = false
            state.isGettingStatisticFinance = false
            state.isGettingStatisticOverview = false
            state.isGettingChatByTime = false
        })

        builder.addCase(thunkStatisticMessage.pending, state => {
            state.isGettingStatisticMessage = true
        })
        builder.addCase(thunkStatisticMessage.rejected, (state, action) => {
            state.isGettingStatisticMessage = false
        })
        builder.addCase(thunkStatisticMessage.fulfilled, (state, action) => {
            const {payload} = action
            const {statisticMessage} = payload
            const {from, to, platform, messageByDays} = statisticMessage
            state.message.messageByDays = messageByDays
            state.message.startDate = from
            state.message.endDate = to
            state.message.platform = platform
            state.isGettingStatisticMessage = false
        })

        builder.addCase(thunkStatisticFinance.pending, state => {
            state.isGettingStatisticFinance = true
        })
        builder.addCase(thunkStatisticFinance.rejected, (state, action) => {
            state.isGettingStatisticFinance = false
        })
        builder.addCase(thunkStatisticFinance.fulfilled, (state, action) => {
            const {payload} = action
            const {statisticFinancial} = payload
            const {from, to, platform, total, amountByDays} = statisticFinancial
            state.finance.amountByDays = amountByDays
            state.finance.startDate = from
            state.finance.endDate = to
            state.finance.platform = platform
            state.finance.total = total
            state.isGettingStatisticFinance = false
        })

        builder.addCase(thunkConversationOverview.pending, state => {
            state.isGettingStatisticOverview = true
        })
        builder.addCase(thunkConversationOverview.rejected, (state, action) => {
            state.isGettingStatisticOverview = false
        })
        builder.addCase(thunkConversationOverview.fulfilled, (state, action) => {
            const {payload} = action
            const {overview} = payload
            state.overview = overview
            state.isGettingStatisticOverview = false
        })

        builder.addCase(thunkChatByTime.pending, state => {
            state.isGettingChatByTime = true
        })
        builder.addCase(thunkChatByTime.rejected, (state, action) => {
            state.isGettingChatByTime = false
        })
        builder.addCase(thunkChatByTime.fulfilled, (state, action) => {
            const {payload} = action
            const {sumSessions, sumCustomers, sessions} = payload
            state.chatByTime.sumSessions = sumSessions
            state.chatByTime.sumCustomers = sumCustomers
            state.chatByTime.sessions = sessions
            state.isGettingChatByTime = false
        })
    }
})
export const {reducer, actions} = statisticSlice

export const {} = actions

export default reducer