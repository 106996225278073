import AxiosClient from "./axiosClient";

const MemberApi = {
    invite: (params) => {
        const url = "member/invite"
        return AxiosClient.post(url, params)
    },
    changeRole: (params) => {
        const url = "member/change_role"
        return AxiosClient.put(url, params)
    },
    lock: (params) => {
        const url = "member/change_status"
        return AxiosClient.put(url, params)
    },
    delete: (params) => {
        const url = "member/delete"
        return AxiosClient.delete(url, {data: params})
    },
    getOnlineMember: (params) => {
        const url = "member/list-online"
        return AxiosClient.get(url, {params})
    }
}

export default MemberApi