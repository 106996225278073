import {ic404} from "../../constant/appResource";
import {ButtonOneIcon} from "../button";
import {useNavigate} from "react-router-dom";

export default function EmptyAppPage() {
    const navigate = useNavigate();
    return (
        <div className={"h-screen w-screen flex flex-col items-center justify-center p-2"}>
            <img src={ic404} alt={"ic404"}/>
            <h1 className={"text-lg md:text-3xl lg:text-5xl font-extrabold text-black-3F" +
                " leading-normal" +
                " my-2"}>We can't
                seem to
                find that</h1>
            <h2 className={"font-medium text-base md:text-lg lg:text-xl text-grey-59" +
                " leading-normal my-2"}>The page you're looking
                for
                doesn't exist or has been moved</h2>
            <div className={"my-2"}>
                <ButtonOneIcon
                    isHTMLIcon={true} classIcon={"fa-regular fa-arrow-left"}
                    text={"Trang chủ"}
                    onClick={() => {
                        navigate("/")
                    }}
                    className={"text-white bg-pink-E3 !py-2"}>
                </ButtonOneIcon><
                                  /div>
        </div>
    )
}