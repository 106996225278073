import AxiosClient from "./axiosClient";

const FacebookApi = {
    connect: (params) => {
        const url = "facebook/connect"
        return AxiosClient.post(url, params)
    },
    disconnect: (params) => {
        const url = "facebook/disconnect"
        return AxiosClient.post(url, params)
    },
    changeChatbot: (params) => {
        const url = "facebook/change-chatbot"
        return AxiosClient.put(url, params)
    }
}

export default FacebookApi