import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {REHYDRATE} from "redux-persist";
import WalletApi from "../api/walletApi";

export const thunkGetListBill = createAsyncThunk("auth/getListBill", async (params, thunkAPI) => {
    return WalletApi.listBill(params)
})

export const BillingSlice = createSlice({
    name: "billing", initialState: {
        bills: []
    }, reducers: {},
    extraReducers: builder => {
        builder.addCase(REHYDRATE, (state, action) => {
            state.isGettingListBill = false
        })

        builder.addCase(thunkGetListBill.pending, state => {
            state.isGettingListBill = true
        })
        builder.addCase(thunkGetListBill.rejected, (state, action) => {
            state.isGettingListBill = false
        })
        builder.addCase(thunkGetListBill.fulfilled, (state, action) => {
            const {payload} = action
            const {bills} = payload
            state.bills = bills
            state.isGettingListBill = false
        })
    }
})
export const {reducer, actions} = BillingSlice

export const {} = actions
export default reducer