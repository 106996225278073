import i18n from "i18next"
import {initReactI18next} from "react-i18next";
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
import {vi, en} from "./general/constant/appResource"

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(I18nextBrowserLanguageDetector)
    .init({
        resources: {
            en: {
                translation: en
            },
            vi: {
                translation: vi
            }
        },
        lng: "vi",
        fallbackLng: "en",
        interpolation: {
            escapeValue: false
        }
    });

export default i18n
