import AxiosClient from "./axiosClient";

const TestApi = {
    addTest: (params) => {
        const url = "/test/add"
        return AxiosClient.post(url, params)
    },
    getList: (params) => {
        const url = "/test/list"
        return AxiosClient.get(url, {params})
    }
}

export default TestApi