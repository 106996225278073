export const AppSize = {
    basePadding: 4,
    baseRadius: 2,
    maxSizeFile: 10 * 1024 * 1024
}

export const APP_DOC_SIZE = {
    lg: "16pt",
    md: "12pt",
    sm: "11pt",
    sx: "10px"
}