import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {REHYDRATE} from "redux-persist"
import AccountApi from "../api/accountApi";
import FacebookApi from "../api/facebookApi";
import VnPayApi from "../api/vnPayApi";
import AppConfig from "../general/constant/appConfig";
import DeviceApi from "../api/deviceApi";
import WalletApi from "../api/walletApi";

export const thunkGetProfile = createAsyncThunk("account/profile", async (params, thunkAPI) => {
    return AccountApi.getProfile(params);
})

export const thunkUpdateAccount = createAsyncThunk("account/updateAccount", async (params, thunkAPI) => {
    return AccountApi.update(params);
})

export const thunkChangePwd = createAsyncThunk("account/ChangePwd", async (params, thunkAPI) => {
    return AccountApi.changePwd(params);
})

export const thunkConnectFacebook = createAsyncThunk("account/connectFacebook", async (params, thunkAPI) => {
    return FacebookApi.connect(params)
})

export const thunkCreatePayment = createAsyncThunk("account/createPayment", async (params, thunkAPI) => {
    return VnPayApi.createPayment(params)
})

export const thunkDisconnectFacebookPage = createAsyncThunk("account/deleteFacebookPage", async (params, thunkAPI) => {
    return FacebookApi.disconnect(params)
})

export const thunkChangeChatbotOfFacebookPage = createAsyncThunk("account/changeChatbotOfFacebookPage", async (params, thunkAPI) => {
    return FacebookApi.changeChatbot(params)
})

export const thunkAddDevice = createAsyncThunk("account/addDevice", async (params, thunkAPI) => {
    return DeviceApi.add(params)
})

export const thunkGetWallet = createAsyncThunk("account/getWallet", async (params, thunkAPI) => {
    return WalletApi.getWallet(params)
})

export const thunkGetAllAccounts = createAsyncThunk("account/getAlls", async (params, thunkAPI) => {
    return AccountApi.getAll(params)
})

export const thunkAddMoney = createAsyncThunk("account/addMoney", async (params, thunkAPI) => {
    return AccountApi.addMoney(params)
})

export const accountSlice = createSlice({
    name: "account", initialState: {
        account: {
            accountId: null,
            email: "",
            telephone: null,
            fullname: "",
            city: null,
            country: null,
            avatar: null,
            topUps: [],
            wallet: {
                chatbots: [
                    {facebookPages: []}
                ]
            },
            members: [],
            notifications: [],
            otpEnabled: false
        },
        accounts: [],
        selectedAccount: {
            accountId: 0
        }
    }, reducers: {
        setAccount: (state, action) => {
            const {payload} = action
            const {account} = payload
            state.account = account
        },
        addChatbot: (state, action) => {
            const {payload} = action
            const {chatbot} = payload
            state.account.wallet.chatbots.push(chatbot)
        },
        addMember: (state, action) => {
            const {payload} = action
            const {member} = payload
            state.account.members.push(member)
        },
        setSelectedAccount: (state, action) => {
            const {payload} = action
            state.selectedAccount = payload
        },
        change2FAStatus: (state, action) => {
            const {payload} = action
            const {status} = payload
            state.account.otpEnabled = status
        }
    }, extraReducers: builder => {

        builder.addCase(REHYDRATE, (state, action) => {
            const {payload} = action;

            if (payload && payload.account) {
                const {account} = payload;
                state.account = account.account;
                state.accounts = account.accounts;
            }

            state.isGettingProfile = false;
            state.isUpdatingAccount = false;
            state.isChangingPwd = false;
            state.isConnectingFacebook = false;
            state.isCreatingPayment = false;
            state.isDisconnectingFacebookPage = false;
            state.isChangingChatbotOfFacebookPage = false;
            state.isGettingWallet = false;
        });

        builder.addCase(thunkGetProfile.pending, state => {
            state.isGettingProfile = true
        })
        builder.addCase(thunkGetProfile.rejected, (state, action) => {
            state.isGettingProfile = false
        })
        builder.addCase(thunkGetProfile.fulfilled, (state, action) => {
            const {payload} = action
            const {account} = payload
            const {avatar} = account
            state.account = {...account, avatar: avatar ? (avatar.includes('resource') ? AppConfig.baseUrl + avatar : avatar) : null}
            state.isGettingProfile = false
        })

        builder.addCase(thunkUpdateAccount.pending, state => {
            state.isUpdatingAccount = true
        })
        builder.addCase(thunkUpdateAccount.rejected, (state, action) => {
            state.isUpdatingAccount = false
        })
        builder.addCase(thunkUpdateAccount.fulfilled, (state, action) => {
            const {payload} = action
            const {account} = payload
            const {avatar} = account
            state.account = {...account, avatar: avatar ? AppConfig.baseUrl + avatar : null}
            state.isUpdatingAccount = false
        })

        builder.addCase(thunkChangePwd.pending, state => {
            state.isChangingPwd = true
        })
        builder.addCase(thunkChangePwd.rejected, (state, action) => {
            state.isChangingPwd = false
        })
        builder.addCase(thunkChangePwd.fulfilled, (state, action) => {
            state.isChangingPwd = false
        })

        builder.addCase(thunkConnectFacebook.pending, state => {
            state.isConnectingFacebook = true
        })
        builder.addCase(thunkConnectFacebook.rejected, (state, action) => {
            state.isConnectingFacebook = false
        })
        builder.addCase(thunkConnectFacebook.fulfilled, (state, action) => {
            const {payload} = action
            const {facebookPages} = payload
            if (facebookPages && facebookPages.length > 0) {
                const {chatbotId} = facebookPages[0]
                const index = state.account.members.findIndex((item) => item.chatbot.chatbotId === chatbotId)
                if (index !== -1) {
                    state.account.members[index].chatbot.facebookPages = facebookPages
                }
            }
            state.isConnectingFacebook = false
        })

        builder.addCase(thunkCreatePayment.pending, state => {
            state.isCreatingPayment = true
        })
        builder.addCase(thunkCreatePayment.rejected, (state, action) => {
            state.isCreatingPayment = false
        })
        builder.addCase(thunkCreatePayment.fulfilled, (state, action) => {
            state.isCreatingPayment = false
        })

        builder.addCase(thunkDisconnectFacebookPage.pending, state => {
            state.isDisconnectingFacebookPage = true
        })
        builder.addCase(thunkDisconnectFacebookPage.rejected, (state, action) => {
            state.isDisconnectingFacebookPage = false
        })
        builder.addCase(thunkDisconnectFacebookPage.fulfilled, (state, action) => {
            const {payload} = action
            const {facebookPageIds} = payload
            const {members} = state.account
            for (let i = 0; i < members.length; i++) {
                const {chatbot} = members[i]
                const {facebookPages} = chatbot
                for (let j = 0; j < facebookPages.length; j++) {
                    const facebookPage = facebookPages[j]
                    const index = facebookPageIds.findIndex((facebookPageId) => facebookPageId === facebookPage.facebookPageId)
                    if (index === -1) {
                        continue
                    }

                    state.account.members[i].chatbot.facebookPages.splice(j, 1)
                }
            }
            state.isDisconnectingFacebookPage = false
        })

        builder.addCase(thunkChangeChatbotOfFacebookPage.pending, state => {
            state.isChangingChatbotOfFacebookPage = true
        })
        builder.addCase(thunkChangeChatbotOfFacebookPage.rejected, (state, action) => {
            state.isChangingChatbotOfFacebookPage = false
        })
        builder.addCase(thunkChangeChatbotOfFacebookPage.fulfilled, (state, action) => {
            const {payload} = action
            const {facebookPage} = payload
            const {members} = state.account
            for (let i = 0; i < members.length; i++) {
                const {chatbot} = members[i]
                const {facebookPages} = chatbot
                const indexSource = facebookPages.findIndex((item) => item.facebookPageId === facebookPage.facebookPageId)
                if (chatbot.chatbotId === facebookPage.chatbotId) {
                    state.account.members[i].chatbot.facebookPages.push(facebookPage)
                }

                if (indexSource !== -1) {
                    state.account.members[i].chatbot.facebookPages.splice(indexSource, 1)
                }

            }
            state.isChangingChatbotOfFacebookPage = false
        })

        builder.addCase(thunkGetWallet.pending, state => {
            state.isGettingWallet = true
        })
        builder.addCase(thunkGetWallet.rejected, (state, action) => {
            state.isGettingWallet = false
        })
        builder.addCase(thunkGetWallet.fulfilled, (state, action) => {
            const {payload} = action
            const {wallet} = payload
            const cur = state.account.wallet
            state.account.wallet = {...cur, ...wallet}
            state.isGettingWallet = false
        })

        builder.addCase(thunkGetAllAccounts.pending, state => {

        })
        builder.addCase(thunkGetAllAccounts.rejected, (state, action) => {
        })

        builder.addCase(thunkGetAllAccounts.fulfilled, (state, action) => {
            const {payload} = action
            const {accounts} = payload
            state.accounts = accounts
        })

        builder.addCase(thunkAddMoney.fulfilled, (state, action) => {
            const {payload} = action
            const wallet = payload.updateWallet
            const accountId = wallet.accountId
            state.accounts.forEach((account) => {
                if (account.accountId === accountId) {
                    account.wallet.remainMoney = wallet.remainMoney
                }
            })
        })
    }
})

export const {reducer, actions} = accountSlice

export const {setAccount, addChatbot, addMember, setSelectedAccount, change2FAStatus} = actions


export default reducer