import AxiosClient from "./axiosClient";

const TagApi = {
    getAll: (params) => {
        const url = `/tag/list?chatbotId=${params.chatbotId}`;
        return AxiosClient.get(url);
    },
    addTag : (params) => {
        const url = "/tag/add";
        return AxiosClient.post(url, params);
    },
    deleteTag : (params) => {
        const url = "/tag/delete?tagId=" + params.tagId;
        return AxiosClient.delete(url);
    },
    updateTag : (params) => {
        const url = "/tag/update";
        return AxiosClient.put(url, params);
    },
    reportTag : (params) => {
        const url = "/tag/statistic"
        return AxiosClient.get(url, {params});
    },
    tagReport: (params) => {
        const url = "/tag/tag-report"
        return AxiosClient.get(url, {params, responseType: 'arraybuffer'})
    } 
 
}

export default TagApi;