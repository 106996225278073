import AxiosClient from "./axiosClient";

const ChatbotIndicatorApi = {
    getOrCreate: (params) => {
        const url = "chatbot-indicator"
        return AxiosClient.get(url, {params})
    },
    update: (params) => {
        const url = "chatbot-indicator/update"
        return AxiosClient.put(url, params)
    }
}

export default ChatbotIndicatorApi