import AxiosClient from "./axiosClient";

const WalletApi = {
    listBill: (params) => {
        const url = "wallet/list-bill"
        return AxiosClient.get(url, {params})
    },
    getWallet: (params) => {
        const url = "wallet"
        return AxiosClient.get(url, {params})
    }
}

export default WalletApi