import {createTransform} from 'redux-persist';

// Transform để tuần tự hóa và giải tuần tự hóa Date
const dateTransform = createTransform(
    // Transform state on its way to being serialized and persisted.
    (inboundState, key) => {
        if (inboundState && inboundState.startDate) {
            return {
                ...inboundState,
                startDate: inboundState.startDate instanceof Date ? inboundState.startDate.toISOString() : inboundState.startDate,
            };
        }
        return inboundState;
    },
    // Transform state being rehydrated
    (outboundState, key) => {
        if (outboundState && outboundState.startDate) {
            return {
                ...outboundState,
                startDate: new Date(outboundState.startDate),
            };
        }
        return outboundState;
    },
    {whitelist: ['chat']} // Chỉ áp dụng transform này cho slice 'chat'
);

export default dateTransform
