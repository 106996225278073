import PreferenceKey from "../constant/preferenceKey";
import {removeAccessToken, updateAccessToken} from "../../api/axiosClient";
import {removeSocketIOToken, updateSocketIOToken} from "../../socketio/socketIoClient";
import AppConstant, {roleMember} from "../constant/appConstant";
import {soundMessage} from "../constant/appResource";
import {
    addSeconds,
    differenceInMilliseconds,
    format,
    formatDuration,
    intervalToDuration,
    intlFormat,
    isValid
} from "date-fns"

const UserHelper = {
    validKey: () => {
        return !!localStorage.getItem(PreferenceKey.accessToken)
    },

    // Sign out
    signOut: () => {
        localStorage.removeItem(PreferenceKey.accessToken);
        localStorage.removeItem(PreferenceKey.refreshToken);
        removeAccessToken()
        removeSocketIOToken()
    },
    saveToken : (payload) => {
        const {accessToken, refreshToken} = payload
        const {token} = accessToken
        localStorage.setItem(PreferenceKey.accessToken, token)
        localStorage.setItem(PreferenceKey.refreshToken, refreshToken.token)
        updateAccessToken(token)
        updateSocketIOToken(token)
    },
    textToInt: (value) => {
        return Number.parseInt(value.toString())
    },
    sizeToText: (size) => {
        const KB = 1024
        const MB = 1024 * 1024
        const GiB = 1024 * 1024
        if (size < KB) {
            return Math.round(size / 1) + 1 + "B"
        } else if (size < MB) {
            return Math.round(size / 1024) + 1 + "KB"
        } else if (size < GiB) {
            return Math.round(size / (1024 * 1024)) + 1 + "MB"
        }
        return ""
    },
    startSoundMessage: () => {
        const sound = new Audio(soundMessage)
        sound.muted = false;
        sound.play().catch(() => {
            sound.pause()
        })
    },
    handleMessageTestV2: (messages = []) => {
        if (messages.length === 0) {
            return []
        }
        const messageGroups = [{
            sender: messages[0].accountId ? "human" : "bot",
            isLeft: messages[0].accountId ? 0 : 1,
            messages: [
                {...messages[0]}
            ]
        }]
        const pages = [{
            messageGroups,
            datetime: messages[0].datetime,
        }]
        for (let i = 1; i < messages.length; i++) {
            const message = messages[i]
            const {datetime, accountId} = message
            const isLeft = !accountId ? 1 : 0
            const sender = isLeft ? "bot" : "human"
            const pageIndex = pages.length - 1
            const pageCurrent = pages[pageIndex]
            const groupIndex = pageCurrent.messageGroups.length - 1
            const groupCurrent = pageCurrent.messageGroups[groupIndex]
            const messageIndex = groupCurrent.messages.length - 1
            const lastMessage = groupCurrent.messages[messageIndex]
            const difference = differenceInMilliseconds(datetime, lastMessage.datetime)

            // Exceeded time limit on one page
            if (difference > AppConstant.splitPageMessageTime) {
                const newMessageGroup = {
                    sender, isLeft, messages: [message]
                }
                pages.push({
                    messageGroups: [newMessageGroup],
                    datetime
                })
                continue;
            }

            // combine message in a group
            if (groupCurrent.isLeft === isLeft) {
                pages[pageIndex].messageGroups[groupIndex].messages.push(message)
                continue;
            }

            const newMessageGroup = {
                sender, isLeft, messages: [message]
            }
            pages[pageIndex].messageGroups.push(newMessageGroup)
        }
        return pages
    },

    handleMessageV2: (messages = []) => {
        if (messages.length === 0) {
            return []
        }
        const messageGroups = [{
            isLeft: messages[0].customerId ? 1 : 0,
            messages: [
                {...messages[0]}
            ]
        }]
        const pages = [{
            messageGroups,
            datetime: messages[0].datetime,
        }]
        for (let i = 1; i < messages.length; i++) {
            const message = messages[i]
            const {datetime, customerId} = message
            const isLeft = !!customerId ? 1 : 0
            const pageIndex = pages.length - 1
            const pageCurrent = pages[pageIndex]
            const groupIndex = pageCurrent.messageGroups.length - 1
            const groupCurrent = pageCurrent.messageGroups[groupIndex]
            const messageIndex = groupCurrent.messages.length - 1
            const lastMessage = groupCurrent.messages[messageIndex]
            const difference = differenceInMilliseconds(datetime, lastMessage.datetime)

            // Exceeded time limit on one page
            if (difference > AppConstant.splitPageMessageTime) {
                const newMessageGroup = {
                    isLeft, messages: [message]
                }
                pages.push({
                    messageGroups: [newMessageGroup],
                    datetime
                })
                continue;
            }

            // combine message in a group
            if (groupCurrent.isLeft === isLeft) {
                pages[pageIndex].messageGroups[groupIndex].messages.push(message)
                continue;
            }

            const newMessageGroup = {
                isLeft, messages: [message]
            }
            pages[pageIndex].messageGroups.push(newMessageGroup)
        }
        return pages
    },
    formatDate: (datetime) => (isValid(new Date(datetime))) ? format(datetime, 'dd/MM/yyyy') : "",
    formatDatetime: (datetime) => (isValid(new Date(datetime))) ? format(datetime, 'HH:mm' +
        ' dd/MM/yyyy') : "",
    formatDateFull: (datetime) => format(datetime, 'HH:mm:ss dd/MM/yyyy'),
    formatCurrency: (value) => Intl.NumberFormat("vi-VN").format(value),
    intlFormatDate: (date, locale) => intlFormat(date, {
        day: "2-digit",
        month: "2-digit",
        year: "2-digit",
        hour: "numeric",
        hour12: false,
        minute: "2-digit",
    }, {locale}),
    formatDuration: (seconds) => {
        const start = new Date()
        const end = addSeconds(start, seconds)
        const duration = intervalToDuration({start, end})
        return formatDuration(duration, {
            format: ["years", "months", "days", "hours", "minutes", "seconds"],
        })
    },
    checkValid({agentRole, desRole}) {
        switch (agentRole) {
            case roleMember.owner:
                switch (desRole) {
                    case roleMember.owner:
                        return false;
                    case roleMember.admin:
                        return true;
                    case roleMember.tester:
                        return true;
                    case roleMember.chatAgent:
                        return true;
                    default:
                        return false;
                }
            case roleMember.admin:
                switch (desRole) {
                    case roleMember.owner:
                        return false;
                    case roleMember.admin:
                        return false;
                    case roleMember.tester:
                        return true;
                    case roleMember.chatAgent:
                        return true;
                    default:
                        return false;
                }
            case roleMember.tester:
                return false
            case roleMember.chatAgent:
                return false
            default:
                return false;
        }
    },
    filterValueEmptyIObject: (object) => {
        let filteredObj = {};
        for (let key in object) {
            if (object[key] !== "" && object[key] !== null) {
                filteredObj[key] = object[key];
            }
        }
        return filteredObj
    },
    generateRandomColor: (length) => {
        const genBrightColor = () => {
            const randomComponent = () => Math.floor(Math.random() * 156) + 100;
            const r = randomComponent().toString(16).padStart(2, '0');
            const g = randomComponent().toString(16).padStart(2, '0');
            const b = randomComponent().toString(16).padStart(2, '0');
            return `#${r}${g}${b}`;
        }

        const uniqueColors = new Set();
        while (uniqueColors.size < length) {
            uniqueColors.add(genBrightColor());
        }
        return Array.from(uniqueColors);
    },
    isValidDate: (d) => {
        return d instanceof Date && !isNaN(d);
    }
}

export default UserHelper;
