import {AppSize} from "../../constant/appSize";

export default function ButtonBasic(
    {
        id,
        text,
        color,
        fontSize = 14,
        fontWeight = 600,
        buttonColor,
        horizontal = 4,
        vertical = 3,
        radius = 4,
        className,
        onClick,
        disable = false,
        props = {}
    }
) {
    return (
        <button
            id={id && id}
            onClick={onClick}
            disabled={disable}
            style={{
                backgroundColor: buttonColor,
                color: color,
                fontWeight,
                lineHeight: 1.21,
                fontSize,
                padding: `${vertical * AppSize.basePadding}px ${horizontal * AppSize.basePadding}px`,
                borderRadius: radius * AppSize.baseRadius
            }}
            className={className} {...props}
        >{text}</button>
    )

}