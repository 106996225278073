import AxiosClient from "./axiosClient";

const MessageReportedApi = {
    add: (params) => {
        const url = "/message-reported/add"
        return AxiosClient.post(url, params)
    },
    list: (params) => {
        const url = "/message-reported/list"
        return AxiosClient.get(url, {params})
    }
}

export default MessageReportedApi