import {AppSize} from "../../constant/appSize";

export default function ButtonOneIcon(
    {
        text,
        color,
        fontSize = 14,
        fontWeight = 600,
        buttonColor,
        horizontal = 4,
        vertical = 3,
        radius = 4,
        isHTMLIcon = true,
        disable = false,
        iconSize,
        classIcon,
        imageSource,
        imageAlt,
        className,
        onClick,
        props = {}
    }
) {
    return (
        <button
            disabled={disable}
            onClick={onClick}
            style={{
                backgroundColor: buttonColor,
                color: color,
                fontWeight,
                lineHeight: 1.21,
                fontSize,
                padding: `${vertical * AppSize.basePadding}px ${horizontal * AppSize.basePadding}px`,
                borderRadius: radius * AppSize.baseRadius
            }}
            className={className}
            {...props}
        >{isHTMLIcon ?
            <i className={`${classIcon}`} style={{fontSize: iconSize, color}}></i> :
            <img src={imageSource} width={iconSize} alt={imageAlt}
                 className={"inline-block"}/>} {text}</button>
    )

}