import AxiosClient from "./axiosClient";

const ProblemApi = {
    aiReport: (params) => {
        const url = "problem/ai-report"
        return AxiosClient.get(url, {params})
    },
    top: (params) => {
        const url = "problem/top"
        return AxiosClient.get(url, {params})
    }
}

export default ProblemApi