import {createBrowserRouter, Navigate} from 'react-router-dom'
import {lazy, Suspense, useEffect} from "react";
import {EmptyAppPage} from "../component/empty";
import preferenceKey from "../constant/preferenceKey";

const AuthRouter = lazy(() => import('../../features/auth/screens'));
const PanelRouter = lazy(() => import('../../features/panel'));
const PrivacyPolicy = lazy(() => import('../../features/privacyPolicy'));
const AdminRouter = lazy(() => import('../../features/admin'));


export const router = createBrowserRouter([
    {
        path: "/", element: (
            <Suspense>
                <Router></Router>
            </Suspense>
        )
    },
    {
        path: "/admin/*", element: (
            <Suspense>
                <PrivateRoute>
                    <AdminRouter></AdminRouter>
                </PrivateRoute>
            </Suspense>
        )
    },
    {
        path: "/auth/*", element: (
            <Suspense>
                <AuthRouter></AuthRouter>
            </Suspense>
        )
    },
    {
        path: "/panel/*", element: (
            <Suspense>
                <PrivateRoute>
                    <PanelRouter></PanelRouter>
                </PrivateRoute>
            </Suspense>
        )
    },
    {
        path: "/privacy_policy/*", element: (
            <Suspense>
                <PrivacyPolicy></PrivacyPolicy>
            </Suspense>
        )
    },
    {
        path: "/empty", element: (
            <></>
        )
    },
    {
        path: "/mobile", element: (
            <Mobile></Mobile>
        )
    },
    {path: "*", element: <EmptyAppPage></EmptyAppPage>}], {})


function Router() {
    useEffect(() => {
        window.location.href = "/auth/sign-in"
    }, []);
    return <></>
}

function PrivateRoute({children}) {

    return !!localStorage.getItem(preferenceKey.accessToken) ? children :
        <Navigate to={"/auth/sign-in"}/>
}

function Mobile() {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://bot.mdcsoftware.com.vn/api/v1/chatbot/script?chatbotId=1&language=vi&device=mobile";
        script.async = true;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <div>
        </div>
    );
};
