import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {REHYDRATE} from "redux-persist"
import PlanApi from "../api/planApi";

export const thunkGetListPlan = createAsyncThunk("planListScreen/getListPlan", async (params, thunkAPI) => {
    return PlanApi.getListPlan(params);
})
export const thunkAddPlan = createAsyncThunk("planListScreen/addPlan", async (params, thunkAPI) => {
    return PlanApi.addPlan(params);
})

export const thunkUpdatePlan = createAsyncThunk("planListScreen/updatePlan", async (params, thunkAPI) => {
    return PlanApi.updatePlan(params);
})


export const planSlice = createSlice({
    name: "plan", initialState: {
        selectedPlan: null,
        plans: []
    }, reducers: {
        setSelectedPlan: (state, action) => {
            const {payload} = action;
            const {planId} = payload
            const index = state.plans.findIndex(plan => plan.planId === planId)
            if (index === -1) {
                state.selectedPlan = null
            } else {
                state.selectedPlan = state.plans[index]
            }
        }
    }, extraReducers: builder => {
        builder.addCase(REHYDRATE, (state, action) => {
            state.isGettingListPlan = false
            state.isAddingPlan = false
            state.isUpdatingPlan = false
        })

        builder.addCase(thunkGetListPlan.pending, state => {
            state.isGettingListPlan = true
        })
        builder.addCase(thunkGetListPlan.rejected, (state, action) => {
            state.isGettingListPlan = false
        })
        builder.addCase(thunkGetListPlan.fulfilled, (state, action) => {
            const {payload} = action
            const {plans} = payload
            state.plans = plans
            state.isGettingListPlan = false
        })

        builder.addCase(thunkAddPlan.pending, state => {
            state.isAddingPlan = true
        })
        builder.addCase(thunkAddPlan.rejected, (state, action) => {
            state.isAddingPlan = false
        })
        builder.addCase(thunkAddPlan.fulfilled, (state, action) => {
            const {payload} = action
            const {plan} = payload
            state.plans.push(plan)
            state.isAddingPlan = false
        })

        builder.addCase(thunkUpdatePlan.pending, state => {
            state.isUpdatingPlan = true
        })
        builder.addCase(thunkUpdatePlan.rejected, (state, action) => {
            state.isUpdatingPlan = false
        })
        builder.addCase(thunkUpdatePlan.fulfilled, (state, action) => {
            const {payload} = action
            const {plan} = payload
            const {planId} = plan
            const index = state.plans.findIndex(plan => plan.planId === planId)
            if (index !== -1) {
                state.plans.splice(index, 1, plan)
            }
            state.isUpdatingPlan = false
        })
    }
})
export const {reducer, actions} = planSlice

export const {setSelectedPlan} = actions

export default reducer