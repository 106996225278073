import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {REHYDRATE} from "redux-persist"
import MessageReportedApi from "../api/messageReportedApi";

const initCurConversation = {
    conversationId: null,
    customer: null,
    chatbot: null,
    messages: [],
    reportedMessage: null
}

export const thunkListReportedConversation = createAsyncThunk("auth/getListReportedConversation", async (params, thunkAPI) => {
    return MessageReportedApi.list(params);
})


export const reportedConversationSlice = createSlice({
    name: "reportedConversation", initialState: {
        conversations: [],
        currentConversation: initCurConversation,
    }, reducers: {
        setSelectedConversation: (state, action) => {
            const {payload} = action
            const {messageId} = payload
            const index = state.conversations.findIndex((conversation) => conversation.reportedMessage.messageId === messageId)
            if (index !== -1) {
                state.currentConversation = state.conversations[index]
            }
        }
    },
    extraReducers: builder => {
        builder.addCase(REHYDRATE, (state, action) => {
            state.isGettingConversation = false
        })

        builder.addCase(thunkListReportedConversation.pending, state => {
            state.isGettingConversation = true
        })
        builder.addCase(thunkListReportedConversation.rejected, (state, action) => {
            state.isGettingConversation = false
        })
        builder.addCase(thunkListReportedConversation.fulfilled, (state, action) => {
            const {payload} = action
            const {conversations} = payload
            state.conversations = conversations
            state.currentConversation = {...initCurConversation}
            state.isGettingConversation = false
        })
    }
})
export const {reducer, actions} = reportedConversationSlice

export const {setSelectedConversation} = actions
export default reducer