import AxiosClient from "./axiosClient";

const DocumentApi = {
    upload: (data) => {
        const url = "/document/upload"
        const bodyFormData = new FormData()
        for (const [key, value] of Object.entries(data)) {
            bodyFormData.append(key, value)
        }
        return AxiosClient({
            method: "post",
            url,
            headers: {"Content-Type": "multipart/form-data"},
            data: bodyFormData
        })

    },
    active: (params) => {
        const url = "/document/active"
        return AxiosClient.put(url, params)
    },
    rename: (params) => {
        const url = "/document/rename"
        return AxiosClient.put(url, params)
    },
    delete: (params) => {
        const url = "/document/delete"
        return AxiosClient.post(url, params)
    },
    test: (params) => {
        const url = "/document/test"
        return AxiosClient.put(url, params)
    },
}

export default DocumentApi