import AxiosClient from "./axiosClient";

const ConversationEvaluatationApi = {
    report: (params) => {
        const url = "/conversation-evaluation/report"
        return AxiosClient.get(url, {params})
    },
    exportReport: (params) => {
        const url = "/conversation-evaluation/export-report"
        return AxiosClient.get(url, {params,responseType: 'arraybuffer'})
    },
    reportByGroup: (params) => {
        const url = "/conversation-evaluation/report-by-group"
        return AxiosClient.get(url, {params})
    },
    exportReportByGroup: (params) => {
        const url = "/conversation-evaluation/export-report-by-group"
        return AxiosClient.get(url, {params,responseType: 'arraybuffer'})
    }  
}

export default ConversationEvaluatationApi;