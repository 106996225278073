import AxiosClient from "./axiosClient";

const CustomerApi = {
    getList: (params) => {
        const url = 'customer/list'
        return AxiosClient.get(url, {params})
    },
    update: (params) => {
        const url = "customer/update"
        return AxiosClient.put(url, params)
    },
    top: (params) => {
        const url = "customer/top"
        return AxiosClient.get(url, {params})
    }
}

export default CustomerApi