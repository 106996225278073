import ModalBasic from "./modalBasic";

export default function ModalApp(
    {
        isOpen = false,
        setIsOpen = () => {
        },
        disableClose = false,
        headerText = "",
        children
    }
) {
    const closeModal = () => {
        setIsOpen(false);
    }
    return (
        <ModalBasic isOpen={isOpen} setIsOpen={setIsOpen} disableClose={disableClose}>
            <div>
                <div className={"flex items-center justify-between"}>
                    <h1 className={"text-black-3F leading-normal mr-2"}>{headerText}</h1>
                    <div
                        className={"w-8 h-8 flex items-center justify-center rounded-lg" +
                            " bg-white-F8 cursor-pointer"} onClick={closeModal}>
                        <i className="fa-regular fa-xmark text-black-3F"></i>
                    </div>
                </div>
                {children}
            </div>
        </ModalBasic>
    )
}