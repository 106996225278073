import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {REHYDRATE} from "redux-persist"
import CustomerApi from "../api/customerApi";
import AppConstant from "../general/constant/appConstant";

export const thunkGetListCustomer = createAsyncThunk("customer/getList", async (params, thunkAPI) => {
    return CustomerApi.getList(params);
})
export const customerSlice = createSlice({
    name: "chatbot", initialState: {
        take: AppConstant.pageMessage * 10,
        customers: []
    }, reducers: {}, extraReducers: builder => {

        builder.addCase(REHYDRATE, (state, action) => {
            state.isGettingListCustomer = false
        })

        builder.addCase(thunkGetListCustomer.pending, state => {
            state.isGettingListCustomer = true
        })
        builder.addCase(thunkGetListCustomer.rejected, (state, action) => {
            state.isGettingListCustomer = false
        })
        builder.addCase(thunkGetListCustomer.fulfilled, (state, action) => {
            state.isGettingListCustomer = false
            const {payload} = action
            const {customers} = payload
            state.customers = customers
        })
    }
})
export const {reducer, actions} = customerSlice


export const {setChatbotId} = actions

export default reducer