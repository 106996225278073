import React from 'react';
import {ToastContainer} from 'react-toastify';

function AppToast() {
    return (
        <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss={false}
            className={"text-xs font-medium text-black-3F leading-normal text-pretty"}
            draggable
            pauseOnHover
        />
    );
}

export default AppToast;
