import React, {useCallback, useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import Viewer from 'react-viewer';
import axios from "axios";

let showImageModal, hideImageModal;

const ACTION_TYPE_VALUE = {
    ZOOM_IN: 1,
    ZOOM_OUT: 2,
    DOWNLOAD: 11
}

function ModalImage() {
    const [isOpen, setIsOpen] = useState(false);
    const [source, setSource] = useState("");

    const downloadImage = useCallback(async () => {
        if (!!source) {
            const response = await axios.get(source, {responseType: 'blob'});
            const blob = new Blob([response.data], {type: response.data.type});
            const mimeType = blob.type;
            let extension = '';
            switch (mimeType) {
                case "image/jpeg":
                    extension = "jpg"
                    break
                case "image/png":
                    extension = 'png';
                    break
                case "image/gif":
                    extension = "gif"
                    break
                default :
                    extension = 'bin';
            }
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `download.${extension}`; // Name of the file to download
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
        }
    }, [source])

    useEffect(() => {
        showImageModal = (content) => {
            setSource(content);
            // setIsOpen(true);
            setTimeout(() => setIsOpen(true), 10); // Small delay before opening the modal
        };

        hideImageModal = () => {
            setIsOpen(false);
            setTimeout(() => setSource(""), 10); // Delay clearing the source to avoid flicker
        };
    }, []);


    return ReactDOM.createPortal(
        <Viewer
            visible={isOpen}
            onClose={() => hideImageModal()}
            images={[{src: source, alt: "image", downloadUrl: source}]}
            scalable={true}
            attribute={false}
            zoomable={true}
            noNavbar={true}
            showTotal={false}
            changeable={false}
            noImgDetails={true}
            zoomSpeed={0.2}
            loop={false}
            downloadable={true}
            downloadInNewWindow={true}
            disableKeyboardSupport={true}
            noResetZoomAfterChange={true}
            rotatable={false}
            customToolbar={toolbars => {
                return [
                    {
                        key: ACTION_TYPE_VALUE.ZOOM_IN,
                        render: <i className="fa-regular fa-plus"></i>,
                        actionType: ACTION_TYPE_VALUE.ZOOM_IN
                    },
                    {
                        key: ACTION_TYPE_VALUE.ZOOM_OUT,
                        render: <i className="fa-regular fa-minus"></i>,
                        actionType: ACTION_TYPE_VALUE.ZOOM_OUT
                    },
                    {
                        key: ACTION_TYPE_VALUE.DOWNLOAD,
                        render: <i className="fa-regular fa-download"></i>,
                        onClick: activeImage => {
                            downloadImage().then()
                        }
                    }
                ]
            }}
        ></Viewer>
        ,
        document.body
    );
}

export {showImageModal, hideImageModal}

export default ModalImage;
